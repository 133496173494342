@import 'styles/mixins/media-queries';
@import 'styles/mixins/pod-3-fonts';
@import 'styles/mixins/fonts-2024';

.hero {
	overflow: hidden;
	position: relative;
	height: 100dvh;
	display: flex;

	img {
		height: 100%;
	}

	:global(.content) {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 120px 0;
	}
}

.login_modal {
	border-radius: 8px;
	background: rgba(255, 255, 255, 0.1);
	backdrop-filter: blur(50px);
	display: flex;
	width: min(535px, calc(100% - 32px));
	padding: 56px 70px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	row-gap: 24px;
	min-height: 320px;

	@include sm-down {
		padding: 40px 20px 30px;
	}

	h1 {
		@include headline6;
		text-align: center;

		@include sm-down {
			font-size: 1.5rem;
		}
	}

	h2 {
		@include body4;
		text-align: center;

		@include sm-down {
			font-size: 0.9rem;
		}
	}

	input {
		border-radius: 4px;
		border: none;
		outline: 1px solid #fff;
		width: 100%;
		display: flex;
		height: 54px;
		padding: 12px 16px;
		justify-content: center;
		align-items: center;
		gap: 16px;
		align-self: stretch;
		background-color: transparent;

		color: white;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 140%;
		letter-spacing: 0.16px;

		&::placeholder {
			color: #fff;
		}

		&:focus {
			outline-color: #aaa;

			&::placeholder {
				color: #aaa;
			}
		}
	}

	.cta_button,
	.empty_button {
		width: 100%;
		border-radius: 8px;
		background: white;
		backdrop-filter: blur(12px);
		display: flex;
		padding: 16px 32px;
		justify-content: center;
		align-items: center;
		gap: 6px;
		align-self: stretch;
		font-size: 16px;
	}

	.error {
		color: red;
	}

	.empty_button {
		background: transparent;
		backdrop-filter: none;
		color: white;
	}

	.message {
		color: #dbdbdb;
		font-size: 14px;
		text-align: center;
		margin-top: -10px;
	}
}
